import React, {useState} from "react"
import {useSelector} from "react-redux"
import {Button} from "rsuite"
import {selectAccount, selectContext, selectOrganization, selectQueryParams} from "../store/entries-slice"
import {selectCurrentPeriod} from "../store/state-slice"
import {useAccountingEntriesQuery} from "../store/accounting-api"
import {doDownload} from "../../utilities/download";

export default function ExcelDownload() {
    const context = useSelector(selectContext)
    const organization = useSelector(selectOrganization)
    const period = useSelector(selectCurrentPeriod(organization))
    const params = useSelector(selectQueryParams)
    const account = useSelector(selectAccount)
    const [loading, setLoading] = useState(false)
    const {isFetching, data} = useAccountingEntriesQuery({context, organization, period, ...params})
    const ids = Array.from(new Set((data?.entries ?? []).map(({booking}) => booking).values()))

    return (
        <Button size="sm" loading={isFetching || loading} disabled={!ids.length}
                onClick={() => doDownload("accounting/excel-download", ids, setLoading, "buchhaltung.xlsx", {account})}>
            <span className="fa fa-file-export"/>
        </Button>
    )
}
