import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux"
import {I18N} from "aurelia-i18n"
import {Container} from "aurelia-dependency-injection"
import {Button, Checkbox, Table, Tooltip, Whisper} from "rsuite"
import moment from "moment-timezone"
import {CurrencyValueConverter} from "../../currency/currency-value-converter"
import {EntryDetails} from "../entry/details"
import {useAccountingEntriesQuery, useDeleteAccountingBookingMutation} from "../store/accounting-api"
import {selectCurrentPeriod} from "../store/state-slice"
import StatsLabel from "../../statistics/time-aggregation/stats-label"
import StateIcon from "./state-icon"
import {
    clear,
    selectClearable,
    selectCombinedCrDr,
    selectContext,
    selectExpandedBookings,
    selectOrganization,
    selectQueryParams,
    selectSelected,
    selectShowCleared,
    selectShowUncleared,
    selectSort,
    selectSum,
    toggleExpandedBooking,
    toggleSelect,
    toggleSelectAll,
    toggleSort,
    update
} from "../store/entries-slice"
import EditButton from "../entry/edit-button"
import {selectEditorDrawer} from "../store/editor-slice"
import confirm from "../../dialog/confirm"
import useAccountingStyles from "../styles";
import SioIcon from "../../icon/rsuite-icon-font/SioIcon";

const i18n = Container.instance.get(I18N)
/** @type CurrencyValueConverter */
const currencyConverter = Container.instance.get(CurrencyValueConverter)

export default function EntriesTable({height}) {
    const dispatch = useDispatch()
    const context = useSelector(selectContext)
    const organization = useSelector(selectOrganization)
    const period = useSelector(selectCurrentPeriod(organization))
    const expandedRowKeys = useSelector(selectExpandedBookings)
    const combinedCrDr = useSelector(selectCombinedCrDr)
    const params = useSelector(selectQueryParams)
    const [sortColumn, sortType] = useSelector(selectSort)
    const [crSum, drSum] = useSelector(selectSum)
    const canClear = 1 < useSelector(selectClearable).length
    const showCleared = useSelector(selectShowCleared)
    const showUncleared = useSelector(selectShowUncleared)
    const {isFetching: loading, data} = useAccountingEntriesQuery({
        context,
        organization,
        period, ...params
    })
    const {table} = useAccountingStyles()
    const {entries = [], amounts = [], currency, clearable = [], states = [], hasCleared, hasUncleared} = data ?? {}

    useEffect(() => () => {
        dispatch(clear())
    }, [])

    useEffect(
        () => {
            console.debug("update state")
            dispatch(update(amounts, currency, clearable, states, hasCleared, hasUncleared))
        }, [
            JSON.stringify(amounts),
            currency,
            clearable.length,
            states.length,
            hasCleared,
            hasUncleared
        ]
    )

    return (
        <Table
            virtualized
            loading={loading}
            data={(showCleared && showUncleared) ? entries :
                showUncleared ? entries.filter(({cleared}) => !cleared?.length) :
                    showCleared ? entries.filter(({cleared}) => !!cleared?.length) : []
            }
            height={height}
            headerHeight={60}
            affixHorizontalScrollbar
            rowKey="booking"
            expandedRowKeys={expandedRowKeys}
            shouldUpdateScroll={false}
            rowExpandedHeight={250}
            className={table}
            sortColumn={sortColumn}
            sortType={sortType ? "asc" : "desc"}
            renderRowExpanded={booking => <EntryDetails booking={booking}/>}
            onSortColumn={column => dispatch(toggleSort(column))}
        >
            <Table.Column key="meta" width={canClear ? 60 : ("journal" !== context ? 40 : 20)} fixed>
                <Table.HeaderCell>
                    {canClear && <span><SelectAll/></span>}
                </Table.HeaderCell>
                <Table.Cell>
                    {({booking, state, cleared}) => (
                        <>
                            {canClear && <span><Selector booking={booking}/></span>}
                            <span><ExpandButton id={booking}/></span>
                            {"journal" !== context &&
                                <span><StateIcon state={state} cleared={!!cleared?.length}/></span>}
                        </>
                    )}
                </Table.Cell>
            </Table.Column>

            {"stack" !== context && !period && (
                <Table.Column key="period" width={80} align="right" fixed>
                    <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.year')}</Table.HeaderCell>
                    <Table.Cell>
                        {({period: id, periodLabel: objectLabel}) => id && (
                            <StatsLabel label={{
                                id, objectLabel, modelId: "accounting/period", displayView: "accounting/period"
                            }}/>
                        )}
                    </Table.Cell>
                </Table.Column>
            )}

            {"stack" !== context && (
                <Table.Column key="bookSequence" width={80} align="right" fixed sortable>
                    <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.bookSequence')}</Table.HeaderCell>
                    <Table.Cell dataKey="bookSequence"/>
                </Table.Column>
            )}

            <Table.Column key="bookDate" width={100} align="right" fixed sortable>
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.bookDate')}</Table.HeaderCell>
                <Table.Cell dataKey="bookDate">
                    {({bookDate}) => bookDate && moment(bookDate).format("L")}
                </Table.Cell>
            </Table.Column>

            {"journal" === context && (
                <Table.Column key="account" width={100} align="right" fixed sortable>
                    <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.account')}</Table.HeaderCell>
                    <Table.Cell dataKey="account">
                        {({account, accountCode, accountName}) => (
                            <AccountLink id={account} code={accountCode} name={accountName}/>
                        )}
                    </Table.Cell>
                </Table.Column>
            )}

            <Table.Column key="contraAccount" width={100} align="right" fixed sortable>
                <Table.HeaderCell>{i18n.tr("accounting.ledger-account.table.contraAccount")}</Table.HeaderCell>
                <Table.Cell dataKey="contraAccount">
                    {({contraAccount, contraAccountCode, contraAccountName, account, parts}) => {
                        if (contraAccount) {
                            return (
                                <AccountLink id={contraAccount} code={contraAccountCode} name={contraAccountName}/>
                            )
                        }
                        const accounts = parts
                            .filter(p => p.account !== account)
                            .map(p => [Math.abs((p.cr?.amount ?? 0) - (p.dr?.amount ?? 0)), p.account, p.accountCode, p.accountName])
                            .sort(([a], [b]) => b - a)
                        const first = accounts.shift()
                        return (
                            <>
                                <AccountLink id={first[1]} code={first[2]} name={first[3]}/>
                                <small style={{fontSize: "50%"}}>
                                    {accounts.map(([_, id, code, name]) => (
                                        <React.Fragment key={id}>
                                            <AccountLink id={id} code={code} name={name}/>
                                            {" "}
                                        </React.Fragment>
                                    ))}
                                </small>
                            </>
                        )
                    }}
                </Table.Cell>
            </Table.Column>

            {combinedCrDr && (
                <Table.Column key="combinedCrDr" width={120} align="right" fixed>
                    <Table.HeaderCell>
                        Soll / Haben
                        {currency && (
                            <strong>
                                {currencyConverter.toView({amount: crSum - drSum, currency}, "finance")}
                            </strong>
                        )}
                    </Table.HeaderCell>
                    <Table.Cell>
                        {({dr, cr}) => currencyConverter.toView({
                            amount: (cr?.amount ?? 0) - (dr?.amount ?? 0), currency
                        }, "finance")}
                    </Table.Cell>
                </Table.Column>
            )}

            {!combinedCrDr && (
                <Table.Column key="dr" width={120} align="right" fixed>
                    <Table.HeaderCell>
                        Soll
                        {currency && (
                            <strong>{currencyConverter.toView({amount: drSum, currency})}</strong>
                        )}
                    </Table.HeaderCell>
                    <Table.Cell>{({dr}) => dr && currencyConverter.toView(dr)}</Table.Cell>
                </Table.Column>
            )}

            {!combinedCrDr && (
                <Table.Column key="cr" width={120} align="right" fixed>
                    <Table.HeaderCell>
                        Haben
                        {currency && (
                            <strong>{currencyConverter.toView({amount: crSum, currency})}</strong>
                        )}
                    </Table.HeaderCell>
                    <Table.Cell>{({cr}) => cr && currencyConverter.toView(cr)}</Table.Cell>
                </Table.Column>
            )}

            <Table.Column key="receiptDate" width={120} align="right" sortable>
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.investmentDate')}</Table.HeaderCell>
                <Table.Cell
                    dataKey="receiptDate">{({receiptDate}) => receiptDate && moment(receiptDate).format("L")}</Table.Cell>
            </Table.Column>

            <Table.Column key="receiptNumber" width={120} align="right" sortable>
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.voucherNumber')}</Table.HeaderCell>
                <Table.Cell dataKey="receiptNumber"/>
            </Table.Column>

            <Table.Column key="reference" width={160} align="left">
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.reference')}</Table.HeaderCell>
                <Table.Cell>{({reference}) => reference && <StatsLabel label={reference}/>}</Table.Cell>
            </Table.Column>

            <Table.Column key="subject" flexGrow={1}>
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.regarding')}</Table.HeaderCell>
                <Table.Cell dataKey="subject"/>
            </Table.Column>

            {"stack" === context && (
                <Table.Column key="actions" align="right">
                    <Table.HeaderCell/>
                    <Table.Cell>
                        {({booking}) => (
                            <>
                                <EditButton id={booking} small/>
                                <DeleteButton id={booking} small/>
                            </>
                        )}
                    </Table.Cell>
                </Table.Column>
            )}
        </Table>
    )
}

function SelectAll() {
    const dispatch = useDispatch()
    const clearable = useSelector(selectClearable)
    const selected = useSelector(selectSelected)

    return (
        <Checkbox
            inline
            indeterminate={(0 < selected.length) && (selected.length < clearable.length)}
            checked={selected.length === clearable.length}
            onChange={() => dispatch(toggleSelectAll())}
        />
    )
}

function Selector({booking}) {
    const dispatch = useDispatch()
    const clearable = useSelector(selectClearable)
    const selected = useSelector(selectSelected)

    return (
        <Checkbox
            inline
            disabled={!clearable.includes(booking)}
            checked={selected.includes(booking)}
            onChange={() => dispatch(toggleSelect(booking))}
        />
    )
}

function ExpandButton({id}) {
    const dispatch = useDispatch()
    const expanded = useSelector(selectExpandedBookings)

    return (
        <SioIcon
            icon={(expanded.includes(id) ? "minus" : "plus") + "-square-o"}
            onClick={() => dispatch(toggleExpandedBooking(id))}
            style={{cursor: "pointer"}}
        />
        // <IconButton
        //     size="xs" appearance="link"
        //     onClick={() => dispatch(toggleExpandedBooking(id))}
        //     icon={<Icon icon={`${expanded.includes(id) ? "minus" : "plus"}-square-o`}/>}
        // />
    );
}

const AccountLink = ({id, name, code}) => (
    <Whisper trigger="hover" placement="bottomEnd" speaker={<Tooltip>{name}</Tooltip>}>
        <div>
            <StatsLabel label={{
                id,
                modelId: "accounting/ledger-account",
                displayView: "accounting/ledger-account",
                objectLabel: code
            }}/>
        </div>
    </Whisper>
)

function DeleteButton({id}) {
    const [doDelete, {isLoading}] = useDeleteAccountingBookingMutation()
    const [open] = useSelector(selectEditorDrawer)

    function handleClick() {
        confirm("Buchung löschen", "Sind Sie sicher?").then(() => {
            doDelete(id)
        }, () => {
        })
    }

    return (
        <Button disabled={open} loading={isLoading} onClick={() => handleClick()} size="xs" appearance="subtle">
            <SioIcon icon="fa fa-trash"/>
        </Button>
    )
}
